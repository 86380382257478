import request from "@/utils/request";
// import { getToken } from "@/utils/auth";

export function getSmsLog(query) {
  return request({
    url: "/admin/base/smsLogList",
    method: "get",
    params: query
  });
}

