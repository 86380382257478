<template>
  <div>
    <el-card>
      <!-- 查询区域 -->
      <el-form ref="form" :inline="true">
        <el-form-item label="手机号">
          <el-input
            placeholder="请输入手机号"
            v-model="queryParams.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit"  icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格区域 -->
      <el-table :data="orderList" stripe style="width: 100%" border>
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
        <el-table-column prop="phone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="code" label="验证码" align="center">
        </el-table-column>
        <el-table-column prop="operation" label="操作名" align="center">
        </el-table-column>
        <el-table-column prop="fee" label="扣费" align="center">
        </el-table-column>
        <el-table-column prop="message" label="返回信息" align="center">
        </el-table-column>
        <el-table-column label="发送时间" align="center">
          <template slot-scope="scope">
            {{
              $moment(scope.row.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </template>
        </el-table-column>
         <el-table-column label="操作" align="center">
          <template slot-scope="scope">
              <el-button
                type="success"
                size="small"
                icon="el-icon-info"
                @click="toViewDetails(scope.row)"
                circle
              ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>


      <!-- 详情弹框 -->
      <el-dialog title="详情" :visible.sync="detailsDialog" width="50%">
        <el-form :model="detailsData">
          <el-form-item label="ID：" label-width="100px">
            {{ detailsData.id }}
          </el-form-item>

          <el-form-item label="操作：" label-width="100px">
            {{ detailsData.operation }}
          </el-form-item>
          <el-form-item label="操作手机号：" label-width="100px">
            {{ detailsData.phone }}
          </el-form-item>
          <el-form-item label="验证码：" label-width="100px">
            {{ detailsData.code }}
          </el-form-item>
          <el-form-item label="返回消息：" label-width="100px">
            {{ detailsData.message }}
          </el-form-item>
          <el-form-item label="扣费情况：" label-width="100px">
            {{detailsData.fee}}
          </el-form-item>
          <el-form-item label="请求ID：" label-width="100px">
            {{detailsData.requestId}}
          </el-form-item>
          <el-form-item label="创建时间：" label-width="100px">
            {{
              $moment(detailsData.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </el-form-item>
          <el-form-item label="更新时间：" label-width="100px">
            {{
              $moment(detailsData.update_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getSmsLog } from "@/api/smsLog";
export default {
  data() {
    return {
      orderList: [],
      queryParams: {
        page: 1,
        limit: 10,
        order_sn: "",
        start_time: 0,
        end_time: 0
      },
      total: 0,
      times: null,
      detailsData: {}, //详情数据
      detailsDialog: false //详情弹框
    };
  },

  methods: {
     // 查看详情
    toViewDetails(row) {
      this.detailsData = row;
      this.detailsDialog = true;
    },
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getOrderList();
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getOrderList();
    },
    // 获取全部
    getOrderList() {
      getSmsLog(this.queryParams).then(res => {
        this.orderList = res.data.data;
        this.total = res.data.total;
      });
    },
    onSubmit() {
      this.queryParams.page=1
      if (this.times) {
        this.queryParams.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryParams.end_time = new Date(this.times[1]).getTime() / 1000;
      }
      this.getOrderList();
    }
  },
  created() {
    this.getOrderList();
  }
};
</script>

<style scoped>
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-pager li.active{
  color: #FF6688;
}
</style>
